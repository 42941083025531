<template>
  <pulse-loader v-if="isBusy" :loading="loading" :color="color" :size="size" class="loader_style"></pulse-loader>

   <div class="Orgchart" v-else>
<section class="hero-wrap hero-wrap-2" style="background-image: url('engineerscss/images/hero1.jpg');" data-stellar-background-ratio="0.5">
   <div class="overlay"></div>
   <div class="container">
      <div class="row no-gutters slider-text js-fullheight align-items-end">
         <div class="col-md-9  pb-5">
            <p class="breadcrumbs"><span class="mr-2"><a href="index.html">Home <i class="fa hvr-grow fa-chevron-right"></i></a></span> <span>Organization Chart <i class="fa hvr-grow fa-chevron-right"></i></span></p>
            <h1 class="mb-3 bread" style="word-break: break-all;">Organization Chart</h1>
            <!-- <a data-fancybox="gallery" href="images/flag-malaysia.png"><img src="images/flag-malaysia.png" style="width:50px"></a> -->

         </div>
      </div>
   </div>
</section>
<div v-if="Object.keys(orgchart).length != 0">

<section class="ftco-section" v-for="(data,index) in orgchart" :key="index" > 
    <div class="container">
    <div class="row justify-content-center no-gutters">
      <div class="col-md-12 heading-section text-center  mb-5">
         <span class="subheading">{{data.type}}</span>
         <h2 class="mb-2">Organization {{data.type}}</h2>
      </div>
   </div>
        <img :src="data.banner" class="hvr-float" style="object-fit:contain;width:100%" width="100%" alt="">

    </div>
    

</section>
</div>


   </div>
</template>

<script>
import {get_base_url,get_orgchart} from '../API/api'
   import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import {useMeta} from 'vue-meta'

export default {
name: 'Orgchart',
props: {
    msg: String
},
components: { PulseLoader },
 setup () {
    useMeta({ title: 'Msjaya | Organization Chart ',
    })
  }, 
data() {
    return {
      data: {},
      orgchart : {},
                  isBusy : true,

    }
  },
  beforeMount(){
    this.get_data();
    this.getBase();

  },
  methods: {
     
    async getBase(){
      let host = get_base_url();
      this.base_url = host;
   },
   async get_data(){
       get_orgchart({post : 1}).then(orgchart => {
         //  alert(JSON.stringify(banner))
         
          this.orgchart = orgchart;
                                      this.isBusy = false;

      });
    }
  }
}
</script>
