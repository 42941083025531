<template>

    <meta name="description" content="Free asdsad tutorials">
    <meta name="title" content="Mja">
     
<div class="Header">
<header class="headerr-section ftco-navbar-light ftco_navbar" style="z-index: 2;">
    <div class="headerr-top">
    <!-- style="  background-color: #00B04F;" > -->
    <div class="container-fluid">
        <div class="row" style="align-items: center;">
        <div class="col-md-6">
            <router-link class="site-logo" to="/">
            <img :src=" company.banner" id="site-img" alt="" >
</router-link>
        </div>
        <div class="col-md-6 text-md-right">
            <router-link to="/#quoteform" @click="scrollToElement" id="quote" class="btn round-pill hvr-pulse text-white" style="margin:5px;background-color:#016e32;">
                Request A Quote
            </router-link>


        </div>
        </div>
    </div>

    </div>

    <div class="site-navbar">
    <!-- Logo -->
    <div class="headerr-right">
        <div class="headerr-info-box">
        <div class="hib-icon hvr-grow">
            <i class="fa hvr-grow fa-phone  hvr-grow fa-2x  hvr-grow  root-color"></i>
        </div>
        <div class="hib-text">
            <h6><router-link style="color:black" to="/contact">Contact</router-link></h6>
        </div>
        </div>
        <div class="headerr-info-box">
        <div class="hib-icon hvr-grow">
        <i class="fa hvr-grow fa-map-marker  fa-2x  hvr-grow  root-color" aria-hidden="true"></i>
        </div>
        <div class="hib-text">
            <h6><router-link style="color:black" to="/contact">Address</router-link></h6>
        </div>
        </div>
        <!-- <button class="search-switch"><i class="fa hvr-grow fa-search"></i></button> -->
    </div>
    <!-- Menu -->
    <nav class="site-nav-menu" >
        <ul>
             
        <li
        ><router-link :to="'/'">Home</router-link></li>
        <li><router-link to="/about">About</router-link>
        <ul class="sub-menu" style="z-index:99999">
            
             <li><router-link to="/about">Our Company</router-link></li>

            <li><router-link to="/award">Award</router-link></li>
            <li><router-link to="/orgchart">Organization chart</router-link></li>
            </ul>
        </li>
        <li><router-link to="/services">Service</router-link>
        

        </li>

        <li><router-link to="/project">Project</router-link>
        <!-- <ul class="sub-menu">
            <li><router-link to="/project">Malaysia</router-link></li>

            <li><router-link to="/project">Singapore</router-link></li>
            </ul>
         -->
        </li>


        <li><a onclick="alert('Soon to be updated')">Career</a></li>
        <li><a  onclick="alert('Soon to be updated')">Supply</a></li>
         <!-- <li><router-link to="/supply">Supply</router-link></li> -->
        <li><a  onclick="alert('Soon to be updated')">Investment</a></li>
        <li><a  onclick="alert('Soon to be updated')">News</a></li>
        <!-- <li><router-link to="/career">Career</router-link></li> -->

        <!-- <li><router-link to="/investment">Investment</router-link></li>
        <li><router-link to="/news">News</router-link></li> -->

        <li><router-link to="/contact">Contact</router-link></li>

        </ul>
    </nav>

    </div>
</header>
</div>
</template>

<script>
import {get_company,get_base_url} from '../API/api'

export default {
name: 'Header',
props: {
    msg: String
},
  mounted: function () {
    if(window.location.hash) {
  // Fragment exists
  setTimeout(()=>{
      this.scrollToElement();

  },2000)
}


  },
  data() {
    return {
      company: {},
    base_url : "",

    }
  },
    beforeMount(){
    this.get_data();
    this.getBase();



  },
methods: {

  scrollToElement() {
    const el = document.getElementById('quoteform');

    if (el) {
      // Use el.scrollIntoView() to instantly scroll to the element
      el.scrollIntoView({behavior: 'smooth'});
    }
  },
    async getBase(){
      let host = get_base_url();
      this.base_url = host;
   },
   async updateAlert(){
    alert("Soon to be updated");
},
    
   async get_data(){
      
       get_company({post : 1}).then(company => {
         //  alert(JSON.stringify(banner))

          this.company = company.find(arr=>arr.is_primary == "1");
      });
    
    }


  
}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.feature-box .fb-text {
padding: 20px 17px 23px;
background: #fff;
}
.headerr-info-box{
    margin-right: 20px;
}
.feature-box h5 {
    margin-bottom: 10px;
}
.headerr-right{
    height:31px;
}
@media only screen and (max-width: 600px) {
    #site-img{
        height: 70px;
        width: 70px;
    }
    #servicetitle{
        display: block;
    }
    .site-logo{
        margin-bottom: 5px;
    }
    .slicknav_menu {
        height: 40px;
    }
    .headerr-top {
        padding:0px;
    }
    .img-policy{
        height: 250px !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {


}

/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {
     #site-img{
        height: 75px;
        width : 75px;
    }
    #servicetitle{
        display: none;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    #site-img{
        height: 75px;
        width : 75px;
    }
    #servicetitle{
        display: none;
    }
}



.owl-carousel .owl-stage, .owl-carousel.owl-drag .owl-item {
-ms-touch-action: auto;
    touch-action: auto;

}
.owl-carousel .owl-stage, .owl-carousel.owl-drag .owl-item {
    -ms-touch-action: pan-y !important;
    touch-action: pan-y !important;
}
.milestones-section {
    padding-top: 35px;
    padding-bottom: 15px;
}
.milestone-text h2 {
    font-size: 60px;
    font-weight: 300;
    line-height: 0.9;
    margin-bottom: 5px;
    color: white;
}

    
</style>
