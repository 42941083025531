<template>
 <metainfo>
    <meta name="description" content="About">
</metainfo>
   <pulse-loader v-if="isBusy" :loading="loading" :color="color" :size="size" class="loader_style"></pulse-loader>

   <div class="About"  v-else>
<section class="hero-wrap hero-wrap-2" style="background-image: url('engineerscss/images/hero1.jpg');" data-stellar-background-ratio="0.5">
   <div class="overlay"></div>
   <div class="container">
      <div class="row no-gutters slider-text js-fullheight align-items-end">
         <div class="col-md-9   pb-5">
            <p class="breadcrumbs"><span class="mr-2"><a href="index.html">Home <i class="fa hvr-grow fa-chevron-right"></i></a></span> <span>About us <i class="fa hvr-grow fa-chevron-right"></i></span></p>
            <h1 class="mb-3 bread">About Us</h1>
         </div>
      </div>
   </div>
</section>
<section  v-if="Object.keys(about).length != 0"  class="ftco-section pb-0" style="padding-bottom: 0px;">
   <div class="container">
      <div class="row justify-content-center no-gutters">
         <div class="col-md-12 heading-section text-center   mb-5">
            <span class="subheading">About Us</span>
            <h2 class="mb-2">Introduction and Objective</h2>
         </div>
      </div>
      <div class="row">
         <div class="col-lg-12 wrap-about py-md-5  ">
            <div class="heading-section pr-md-5" >
               <p v-html="about.description"></p>
            </div>
         </div>
      </div>

   </div>
</section>

<div class="site-section bg-light" v-if="Object.keys(company).length != 0" >
   <div class="container">
      <div class="row justify-content-center">
         <div class="col-12 mb-5 text-left">
            <h2 class="section-title text-black mb-4">Ms Jaya Group Of Companies</h2>
         </div>
      </div>
      <div class="row">
         <div class="col-md-6 mb-4 mb-lg-0 col-lg-4" v-for="(data,index) in company" :key="index" >
            <div class="blog-entry">
               <div class="blog-entry-contents">
                  <h3><a href="#">{{data.title}} </a></h3>
                  <div class="headerr-right">
                     <div class="headerr-info-box">
                        <div class="hib-icon hvr-grow">
                           <i class="fa hvr-grow fa-phone  hvr-grow fa-2x  hvr-grow  root-color"></i>
                        </div>
                        <div class="hib-text">
                           <h6>{{data.contact}}</h6>
                           <p>{{data.email}}</p>
                        </div>
                     </div>
                     <div class="headerr-info-box">
                        <div class="hib-icon hvr-grow">
                           <i class="fa hvr-grow fa-map-marker  fa-2x  hvr-grow  root-color" aria-hidden="true"></i>
                        </div>
                        <div class="hib-text">
                           <p>{{data.address}}</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         
      
      </div>
   </div>
</div>
<section class="services-2-section spad" v-if="Object.keys(vision_mision).length != 0">
   <div class="container">
      <div class="service-text">
         <h2>Our Vision And Mision</h2>
      </div>
      <div class="row">
         <div class="col-lg-6 col-md-6" v-for="(data,index) in vision_mision" :key="index">
            <div class="service-item">
               <div class="si-head">
                  <div class="si-icon">
                     <img src="img/icons/cogwheel.png" alt="">
                  </div>
                  <h5>{{data.title}}</h5>
               </div>
               <p>{{data.description}}</p>
            </div>
         </div>

      </div>
   </div>
</section>
<section class="ftco-section" v-if="Object.keys(policy).length != 0">
   <div class="container">
      <div class="row justify-content-center mb-5">
         <div class="col-md-7 heading-section text-center  ">
            <span class="subheading">Policy</span>
            <h2>Our Policy</h2>
         </div>
      </div>
   <div class="row">
      <div class="col-md-12" v-for="(data,index) in policy" :key="index" >
         <div class="row block-7" v-if="index %2 == 0">
            <div class="col-sm-12 col-md-6 block-7">
               <div class="img hvr-pop img-policy"  :style="{ backgroundImage: `url(${data.banner})` }"></div>
            </div>
            <div class="col-sm-12 col-md-6 block-7">
               <div class="p-4">
                  <h2 class="policy_text">{{data.title}}</h2>
                  <div class="p-4">
                     <ul class="pricing-text mb-2" >
                        <li v-for="(datalist,index) in (data.description).split('+')" :key="index"><span class="fa fa-check-circle mr-2"></span>{{datalist}}</li>
                     </ul>
                  </div>
                  <p class="pricing-text mb-2" v-html="data.short_description"></p>
               </div>
            </div>
         </div>
         <div v-else>
               <div class="row block-7">
               <div class="col-sm-12 col-md-6 block-7">
                  <div class="p-4">
                  <h2 class="policy_text">{{data.title}}</h2>
                     <div class="p-4">
                        <ul class="pricing-text mb-2">
                             <li v-for="(datalist,index) in (data.description).split('+')" :key="index"><span class="fa fa-check-circle mr-2"></span>{{datalist}}</li>
                        </ul>
                     </div>
                  <p class="pricing-text mb-2" v-html="data.short_description"></p>
                  </div>
               </div>
            <div class="col-sm-12 col-md-6 block-7">
               <div class="img hvr-pop img-policy"  :style="{ backgroundImage: `url(${data.banner})` }"></div>
            </div>
            </div>
         </div>
      </div>
   </div>
   
   </div>


</section>
    </div>
</template>
<style>
  .loader_style{
    margin: auto;
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
  }
  
@media (max-width: 767.98px) { 
     .block-7 .img {
    height: 200px;
    width: 100%;
}
.policy_text{
   font-size:1.7rem;
}
   }

</style>
<script>
// import {test} from '../API/api'
import {get_base_url,get_about,get_company, get_vision_mision, get_policy} from '../API/api'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import {useMeta} from 'vue-meta'

export default {
name: 'About',
props: {
    msg: String
},
 setup () {
    useMeta({ title: 'Msjaya | About ',
    })
  }, 
 
components: { PulseLoader },

data() {
    return {
      data: {},
            isBusy : true,

       base_url : "",
      about : {},
      vision_mision : {},
      company:{},
   policy : {},
    }
  },
   beforeMount(){
    this.get_data();
    this.getBase();

  },
   methods: {

    async getBase(){
      let host = get_base_url();
      this.base_url = host;
   },
    async get_data(){
       
       get_about({post : 1}).then(about => {

          this.about = about.find(arr=>arr.type == "aboutone");
                             this.isBusy = false;

      });
       get_company({post : 1}).then(com => {

          this.company = com
      });
       get_vision_mision({post : 1}).then(vision_mision => {
          this.vision_mision = vision_mision
                             this.isBusy = false;

      });
        get_policy({post : 1}).then(policy => {

          this.policy = policy
                             this.isBusy = false;

      });
    
    
    }
  }
//   beforeMount(){
//     this.getName();
//   },
//   methods: {
//     async getName(){
//        test({post : 1}).then(test => {
//           this.data.test = test;
//       });
//     }
//   }
}
</script>
