<template>
<pulse-loader v-if="isBusy" :loading="loading" :color="color" :size="size" class="loader_style"></pulse-loader>

   <div class="Supply" v-else>

<section class="hero-wrap hero-wrap-2" style="background-image: url('engineerscss/images/hero1.jpg');" data-stellar-background-ratio="0.5">
   <div class="overlay"></div>
   <div class="container">
      <div class="row no-gutters slider-text js-fullheight align-items-end">
         <div class="col-md-9  pb-5">
            <p class="breadcrumbs"><span class="mr-2"><a href="index.html">Home <i class="fa hvr-grow fa-chevron-right"></i></a></span> <span>Supply <i class="fa hvr-grow fa-chevron-right"></i></span></p>
            <h1 class="mb-3 bread">Supply</h1>
         </div>
      </div>
   </div>
</section>

<!-- Testimonial section end  -->
<!-- Call to action section  -->

<!-- Call to action section end  -->
<!-- Video section  -->
<section class="video-section spad" v-for="(data,index) in supply" :key="index">
   <div class="container" v-if="index % 2 == 0">
      <div class="row">
         <div class="col-lg-6">
            <div class="video-text">
               <h2>{{data.title}}</h2>
               <p>{{data.description}}</p>
               <a ><router-link class="site-btnn hvr-grow hvr-grow" to="/contact">Contact Us</router-link></a>
            </div>
         </div>
         <div class="col-lg-6 hvr-grow">
               <div class="testimonial-bg bg-supply" style="background:url('industryinc/img/testimonial-bg.jpg');    background-size: cover;
         "></div>
         </div>
      </div>
   </div>
   <div class="container" v-else>
      <div class="row">
         <div class="col-lg-6 hvr-grow">
               <div class="testimonial-bg bg-supply" :style="{ backgroundImage: `url(${data.banner})`,backgroundSize : 'cover' }" >
         "></div>
         </div>
         <div class="col-lg-6">
            <div class="video-text">
               <h2>{{data.title}}</h2>
               <p>{{data.description}}</p>
               <a ><router-link class="site-btnn hvr-grow hvr-grow" to="/contact">Contact Us</router-link></a>
            </div>
         </div>
      </div>
   </div>
</section>
<section class="cta-section">
   <div class="container">
      <div class="row">
         <div class="col-lg-9 d-flex align-items-center">
            <h2>We produce or supply Goods, Services, or Sources</h2>
         </div>
         <div class="col-lg-3 text-lg-right" >
            <a ><router-link class="site-btnn hvr-grow sb-dark" to="/contact">Contact Us</router-link></a>
         </div>
      </div>
   </div>
</section>
    </div>
</template>
<style  scoped>
.bg-supply{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
</style>
<script>
// import {test} from '../API/api'
import {get_base_url, get_supply} from '../API/api'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import {useMeta} from 'vue-meta'

export default {
name: 'Project',
props: {
    msg: String
},
 setup () {
    useMeta({ title: 'Msjaya | Supply ',
    })
  }, 
components: { PulseLoader },

data() {
    return {
      data: {},
       base_url : "",
                   isBusy : true,


   supply : {},
    }
  },
   beforeMount(){
    this.get_data();
    this.getBase();

  },
   methods: {

    async getBase(){
      let host = get_base_url();
      this.base_url = host;
   },
    async get_data(){
       
      
        get_supply({post : 1}).then(supply => {
           this.supply = supply;
            this.isBusy = false;

        

      });
    
    
    }
  }
//   beforeMount(){
//     this.getName();
//   },
//   methods: {
//     async getName(){
//        test({post : 1}).then(test => {
//           this.data.test = test;
//       });
//     }
//   }
}
</script>
