<template>
    <div class="NewsDetail">
        <section class="hero-wrap hero-wrap-2" style="background-image: url('engineerscss/images/hero1.jpg');" data-stellar-background-ratio="0.5">
   <div class="overlay"></div>
   <div class="container">
      <div class="row no-gutters slider-text js-fullheight align-items-end">
         <div class="col-md-9  pb-5">
            <p class="breadcrumbs"><span class="mr-2"><a href="index.html">Home <i class="fa hvr-grow fa-chevron-right"></i></a></span> <span>News Details<i class="fa hvr-grow fa-chevron-right"></i></span></p>
            <h1 class="mb-3 bread">News Detail</h1>
         </div>
      </div>
   </div>
</section>
<div class="site-section" v-if="Object.keys(news).length != 0" >
   <div class="container">
      <div class="row">
         <div class="col-md-7 mb-4">
            <img :src='news.banner' alt="Image" class="img-fluid">
         </div>
         <div class="col-md-4 ml-auto">
            <h2 class="text-black mb-4">{{news.title}}</h2>
            <p>{{news.description}}</p>
            <!-- <p><a href="#" class="btn btn-primary rounded-0 py-3 px-5">Visit Site</a></p> -->
         </div>
      </div>
   </div>
</div>
    </div>
</template>

<script>
import {get_base_url, get_news} from '../API/api'
import { useRoute } from 'vue-router'
import {useMeta} from 'vue-meta'

export default {
name: 'NewsDetail',
props: {
    msg: String
},
 setup () {
    useMeta({ title: 'Msjaya | News Detail ',
    })
  }, 
 mounted() {
 

  },
data() {
    return {
      data: {},
       base_url : "",
       news_id : "",

   news : {},
    }
  },
   beforeMount(){
    this.get_data();
    this.getBase();

  },
   methods: {

    async getBase(){
      let host = get_base_url();
      this.base_url = host;
   },
    async get_data(){
       
         const route = useRoute()

   const id = route.params.id
        get_news({slug : id}).then(news => {
           this.news = news.find(arr=>arr.slug == id);

      });
    
    
    }
  }
//   beforeMount(){
//     this.getName();
//   },
//   methods: {
//     async getName(){
//        test({post : 1}).then(test => {
//           this.data.test = test;
//       });
//     }
//   }
}
</script>
