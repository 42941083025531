<template>
  <pulse-loader v-if="isBusy" :loading="loading" :color="color" :size="size" class="loader_style"></pulse-loader>

   <div class="ProjectDetail" v-else>
        <section class="hero-wrap hero-wrap-2" style=" background-image: url('/engineerscss/images/hero1.jpg');" data-stellar-background-ratio="0.5">
   <div class="overlay"></div>
   <div class="container">
      <div class="row no-gutters slider-text js-fullheight align-items-end">
         <div class="col-md-9  pb-5">
            <p class="breadcrumbs"><span class="mr-2"><a href="index.html">Home <i class="fa hvr-grow fa-chevron-right"></i></a></span> <span class="mr-2"><a>Project <i class="fa hvr-grow fa-chevron-right"></i></a></span> <span>Project Detail <i class="fa hvr-grow fa-chevron-right"></i></span></p>
            <h1 class="mb-3 bread" style="word-break: break-all;">{{project.title}}</h1>
         </div>
      </div>
   </div>
</section>
<section class="ftco-section" v-if="Object.keys(project).length != 0">
   <div class="container">
      <div class="row">
         <div class="col-md-8 ">
            <p>
               <img :src="project.banner" alt="" class="img-fluid">
            </p>
            <h2 class="mb-3">{{project.title}}</h2>
            <p>{{project.description}}</p>
         </div>
         <div class="col-md-4 sidebar ">
            <div class="sidebar-box ">
               <h3>Related Project</h3>
               <div class="block-21 mb-4 d-flex"   v-for="(data,index) in recent_project" :key="index" >
                  <a class="blog-img mr-4" :style="{ backgroundImage: `url(${data.banner})` }">
                  </a>
                  <div class="text">
                     <h3 class="heading"><router-link :to="'/projectdetail/' + data.slug">{{data.title}}</router-link></h3>
                     <div class="meta">
                        <div><a href="#"><span class="icon-calendar"></span>{{data.date_of_project}}</a></div>
                     </div>

                  </div>
               </div>
               
            </div>
         </div>
      </div>
   </div>
</section>
<!-- <div class="site-section block-3">
   <div class="container">
      <div class="mb-5">
         <h3 class="section-subtitle">Our Projects Images</h3>
         <h2 class="section-title mb-4">Explore Our <strong>Images</strong></h2>
      </div>
      <div class="projects-carousel-wrap">
         <div class="owl-carousel owl-slide-3">
            <div class="project-item" v-for="(data,index) in recent_project" :key="index" >
               <div class="project-item-contents">
                  <a href="#">
                     <h2 class="project-item-title">
                        {{data.title}}
                     </h2>
                  </a>
               </div>
               <img :src="data.banner" alt="Image" class="img-fluid">
            </div>
         </div>
      </div>
   </div>
</div> -->
    </div>
</template>

<script>
import {get_base_url,get_project_with_category} from '../API/api'
import { useRoute } from 'vue-router'
   import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import {useMeta} from 'vue-meta'

export default {
name: 'ProjectDetail',
props: {
    msg: String
},
components: { PulseLoader },

 setup () {
    useMeta({ title: 'Msjaya | Project Detail ',
    })
  }, 
data() {
    return {
      data: {},
       base_url : "",
       project_id : "",
                  isBusy : true,

   project : {},
   recent_project : {},
    }
  },
  updated : function(){
     this.get_data();
     this.$nextTick(function(){
        window.$('.owl-slide-3').owlCarousel({
					center: false,
					items: 3,
					loop: true,
					stagePadding: 0,
					margin: 30,
					autoplay: true,
					smartSpeed: 500,
					nav: true,
					dots: true,
					navText: ['<span class="icon-arrow_back">', '<span class="icon-arrow_forward">'],
					responsive:{
						600:{
							items: 2
						},
						1000:{
							items: 2
						},
						1200:{
							items: 3
						}
					}
				});
     })
  },
   beforeMount(){
    this.get_data();
    this.getBase();

  },
   methods: {
      async  shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
},

    async getBase(){
      let host = get_base_url();
      this.base_url = host;
   },
    async get_data(){
       
         const route = useRoute()

          const id = route.params.id
          
        get_project_with_category({slug : id}).then(project => {
           this.recent_project = project.filter(arr=>arr.slug != id).slice(0,5);
           this.project = project.find(arr=>arr.slug == id);
            this.isBusy = false;

        

      });
    
    
    }
  }
//   beforeMount(){
//     this.getName();
//   },
//   methods: {
//     async getName(){
//        test({post : 1}).then(test => {
//           this.data.test = test;
//       });
//     }
//   }
}
</script>
