<template>
  <pulse-loader v-if="isBusy" :loading="loading" :color="color" :size="size" class="loader_style"></pulse-loader>

   <div class="Career" v-else>
        <section class="hero-wrap hero-wrap-2" style="background-image: url('engineerscss/images/hero1.jpg');" data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text js-fullheight align-items-end">
                <div class="col-md-9 pb-5">
                    <p class="breadcrumbs"><span class="mr-2"><a href="index.html">Home <i class="fa hvr-grow fa-chevron-right"></i></a></span> <span>Career <i class="fa hvr-grow fa-chevron-right"></i></span></p>
                    <h1 class="mb-3 bread">Career</h1>
                </div>

            </div>
        </div>
        </section>
        <section class="blog-section spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 sidebar ">
                    <div class="sb-widget">
                    <!-- <form class="sb-search">
                        <input type="text" placeholder="Search Job">
                        <button><i class="fa hvr-grow fa-search"></i></button>
                    </form> -->
                    </div>
                    <div class="sb-widget">
                    <h2 class="sb-title">Country</h2>
                    <div class="recent-post">
                        <div class="rp-item">
                            <img src="images/flag-malaysia.png" style="height: 100px;" alt="">
                            <div class="rp-text">
                                <h4>Malaysia</h4>
                            </div>
                        </div>
                           <div class="rp-item">
                            <img src="images/singapore-flag.jpg" style="height: 100px;" alt="">
                            <div class="rp-text">
                                <h4>Singapore</h4>
                            </div>
                        </div>
                        
                    </div>
                    </div>
                    <div class="sb-widget">
                    <h2 class="sb-title">Categories</h2>
                    <ul>
                        <li v-for="(data,index) in career" :key="index" ><a href="">{{data.category}}</a></li>
                        
                    </ul>
                    </div>
                
                </div>
                <div class="col-lg-8 order-1 order-lg-2">
                    <div class="blog-post" v-for="(data,index) in career" :key="index">
                    <div class="blog-thumb" :style="{ backgroundImage: `url(${data.banner})` }">
                        <div class="blog-date">{{data.created_at}}</div>
                    </div>
                    <div class="blog-metas">
                        <div class="blog-meta meta-author">by <a href="">Admin</a></div>
                        <div class="blog-meta meta-cata">in <a href="">{{data.category}}</a></div>
                        <div>
                            <img v-if="data.country == 'malaysia'" src="images/flag-malaysia.png" style="height: 50px;" alt="">
                            <img v-else src="images/singapore-flag.jpg" style="height: 50px;" alt="">

                        </div>
                    </div>
                    <h2>{{data.title}}</h2>
                    <p>{{data.description}}</p>
                    <button data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo" class="site-btnn hvr-grow read-more">Apply</button>
                    </div>
                    
<!--                     
                    <div class="site-pagination">
                    <a href="" class="current">01.</a>
                    <a href="">02.</a>
                    <a href="">03.</a>
                    <a class="next" href="">Next</a>
                    </div> -->
                </div>
            </div>
        </div>
        </section>

        <section class="cta-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-9 d-flex align-items-center">
                    <h2>We produce or supply Goods, Services, or Sources</h2>
                </div>
                <div class="col-lg-3 text-lg-right" >
                    <a ><router-link class="site-btnn hvr-grow sb-dark" to="/contact">Contact Us</router-link></a>
                </div>
            </div>
        </div>
        </section>
              <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Career Apply Message</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <form @submit="submit_form">
               <div class="form-group">
                  <label for="recipient-name" class="col-form-label">Name:</label>
                  <input type="text" v-model="name" class="form-control" id="recipient-name">
               </div>
              
               <div class="form-group">
                  <label for="recipient-name" class="col-form-label">Phone number:</label>
                  <input type="tel" v-model="contact" class="form-control" id="recipient-name">
               </div>
                <div class="form-group">
                  <label for="recipient-name" class="col-form-label">Email:</label>
                  <input type="text" v-model="email" class="form-control" id="recipient-name">
               </div>
               <div class="form-group">
                  <label for="recipient-name" class="col-form-label">Job:</label>
                  <input type="text" v-model="job" class="form-control" id="recipient-name">
               </div>
               <div class="form-group">
                  <label for="message-text" class="col-form-label">Message:</label>
                  <textarea class="form-control" v-model="message" id="message-text"></textarea>
               </div>
         <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-primary">Send message</button>
         </div>
            </form>
      </div>
</div>
</div>
</div>
  
    </div>
</template>

<script>
import {get_base_url,get_career,add_career_msg} from '../API/api'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import {useMeta} from 'vue-meta'

export default {
name: 'Career',
props: {
    msg: String
},
components: { PulseLoader },
 setup () {
    useMeta({ title: 'Msjaya | Career ',
    })
  }, 
data() {
    return {
      data: {},
       base_url : "",
                   isBusy : true,

      career : {},
    }
  },
   beforeMount(){
    this.get_data();
    this.getBase();

  },
   methods: {
       submit_form(){
			alert("Form Submitted , Thank you " + this.name);         
			add_career_msg({name : this.name,email: this.email , job : this.job, contact : this.contact,message : this.message}).then(data => {
				console.log(data)

			});
	},

    async getBase(){
      let host = get_base_url();
      this.base_url = host;
   },
    async get_data(){
       
         get_career({post : 1}).then(career => {

          this.career = career
                                       this.isBusy = false;

      });
    
    }
  }
}
</script>
