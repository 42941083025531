<template>
   <footer id="Footer" class="ftco-footer p-5">
      <div class="container">
         <div class="row mb-5">
            <div class="col-md">
               <div class="ftco-footer-widget mb-4">
                  <h2 class="ftco-heading-2" id="co_name">{{company_primary.title}}.

                  </h2>
                  <div><a href="#" class="meta-chat">
                     <img :src="company_primary.banner"  alt="">
                     </a>
                  </div>
                  <ul class="ftco-footer-social list-unstyled mt-5">
                     <li class="ftco-animate"><a href="#"><span class="fa hvr-grow fa-twitter"></span></a></li>
                     <li class="ftco-animate"><a href="#"><span class="fa hvr-grow fa-facebook"></span></a></li>
                     <li class="ftco-animate"><a href="#"><span class="fa hvr-grow fa-instagram"></span></a></li>
                  </ul>
               </div>
            </div>
            <div class="col-md" v-for="(data,index) in company" :key="index" >
               <div class="ftco-footer-widget mb-4">
                  <h2 class="ftco-heading-2">{{data.title}}</h2>
                  <div class="block-23 mb-3">
                     <ul>
                        <li><span class="icon fa hvr-grow fa-map"></span><span class="text" id="">Copyright 2021 | All Right is Reserved - MS JAYA ENGINEERING</span></li>
                        <li><span class="icon fa hvr-grow fa-map"></span><span class="text" id="co_address">{{data.address}}</span></li>
                        <li><span class="icon fa hvr-grow fa-phone"></span><span class="text" id="co_contact">{{data.contact}}</span></li>
                        <li><span class="icon fa hvr-grow fa-envelope pr-4"></span><span style="word-break: break-all;" class="text">{{data.email}}</span></li>
                     </ul>
                  </div>
               </div>
            </div>
            
         </div>
      </div>
   </footer>
   <!-- loader -->
   <!-- <div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px"><circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/><circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00"/></svg></div> -->
   <div class="search-model">
      <div class="h-100 d-flex align-items-center justify-content-center">
         <div class="search-close-switch">+</div>
         <form class="search-model-form">
            <input type="text" id="search-input" placeholder="Search here.....">
         </form>
      </div>
   </div>
</template>

<script>
import {get_company,get_base_url} from '../API/api'

export default {
name: 'Footer',
props: {
    msg: String
},data() {
    return {
      company: {},
      company_primary : {},
      base_url : "",

    }
  },
    beforeMount(){
    this.get_data();
    this.getBase();



  },
  methods: {

  async getBase(){
      let host = get_base_url();
      this.base_url = host;
   },
  
    async get_data(){
      
       get_company({post : 1}).then(company => {

          this.company_primary = company.find(arr=>arr.is_primary == "1");

          this.company = company.filter(arr=>arr.is_footer == "1");
      });
    
    }
  }
}
</script>
