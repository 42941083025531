<template>
  <pulse-loader v-if="isBusy" :loading="loading" :color="color" :size="size" class="loader_style"></pulse-loader>

   <div class="News" v-else>
<section class="hero-wrap hero-wrap-2" style="background-image: url('engineerscss/images/hero1.jpg');" data-stellar-background-ratio="0.5">
   <div class="overlay"></div>
   <div class="container">
      <div class="row no-gutters slider-text js-fullheight align-items-end">
         <div class="col-md-9  pb-5">
            <p class="breadcrumbs"><span class="mr-2"><a href="index.html">Home <i class="fa hvr-grow fa-chevron-right"></i></a></span> <span>News <i class="fa hvr-grow fa-chevron-right"></i></span></p>
            <h1 class="mb-3 bread">News</h1>
         </div>
      </div>
   </div>
</section>
<section class="ftco-section" v-if="Object.keys(news).length != 0" >
   <div class="container">
      <div class="row justify-content-center no-gutters">
         <div class="col-md-12 heading-section text-center  mb-5">
            <span class="subheading">About Us</span>
            <h2 class="mb-2">News</h2>
         </div>
      </div>
      <div class="row d-flex">
         <div class="col-md-3 d-flex " v-for="(data,index) in news" :key="index" >
            <div class="blog-entry justify-content-end">
               <div class="text">
                  <router-link :to="'/newsdetail/' + data.slug">

                  <a class="block-20 img" :style="{ backgroundImage: `url(${data.banner})` }">
                  </a>
                        </router-link>
                  <div class="meta mb-3">
                     <div><a >{{data.created_at}}</a></div>
                     <div><a >Admin</a></div>
                  </div>
                  <h3 class="heading"><a >{{data.title}}</a></h3>
               </div>
            </div>
         </div>
         
      </div>
      
   </div>
</section>
    </div>

</template>

<script>
// import {test} from '../API/api'

import {get_base_url, get_news} from '../API/api'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import {useMeta} from 'vue-meta'

export default {
name: 'News',
props: {
    msg: String
},
components: { PulseLoader },
 setup () {
    useMeta({ title: 'Msjaya | News ',
    })
  }, 
data() {
    return {
      data: {},
       base_url : "",
            isBusy : true,

   news : {},
    }
  },
   beforeMount(){
    this.get_data();
    this.getBase();

  },
   methods: {

    async getBase(){
      let host = get_base_url();
      this.base_url = host;
   },
    async get_data(){
       
      
        get_news({post : 1}).then(news => {
           this.news = news;
                                        this.isBusy = false;

        

      });
    
    
    }
  }
//   beforeMount(){
//     this.getName();
//   },
//   methods: {
//     async getName(){
//        test({post : 1}).then(test => {
//           this.data.test = test;
//       });
//     }
//   }
}
</script>
