


// const host = "http://localhost/piegenwebsite/";
// const host = "http://3.133.103.220/"
const host = "https://msjaya.herokuapp.com/"

const access_endpoint_link = host + "API/";

const format_request = (postparam) => {


    // postparam['user_token'] = window.localStorage.getItem("user_token");
    // postparam['user_id'] = window.localStorage.getItem("user_id");
    // postparam['role'] = window.localStorage.getItem("role");

    // AsyncStorage.getItem('user_location').then(user_location => {
    // //     console.log('user location XXXXXXXXXXXXXXXXXXXXXXXX');
    // //     console.log(user_location);
    //     postparam['user_location'] = user_location;

        let body = '';
        for (let k in postparam) {
            body += encodeURI(k) + "=" + encodeURI(postparam[k]) + "&";
        }
        console.log(body);
        var postOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: body
        };
        return postOptions;
    // });
}

async function return_response(response,json){
    if(response.ok){
        return json.data;
    }else{
        alert(json.error);
    }
}

export const get_banner = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_banner", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  
}

export const get_project_with_category = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_project_with_category", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  
}
export const add_career_msg = async (postparam) => {

    var response = await fetch(access_endpoint_link + "add_career_msg", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  
}
export const add_investment = async (postparam) => {

    var response = await fetch(access_endpoint_link + "add_investment", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  
}
export const get_video = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_video", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  
}
export const add_contact = async (postparam) => {

    var response = await fetch(access_endpoint_link + "add_contact", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  
}
export const get_about = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_about", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  
}

export const get_data = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_data", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  

}
export const add_quotation = async (postparam) => {

    var response = await fetch(access_endpoint_link + "add_quotation", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  

}
export const get_service_detail = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_service_detail", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  

}
export const get_news = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_news", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  

}
export const get_investment = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_investment", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  
}
export const get_supply = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_supply", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  
}
export const get_service = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_service", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  
}
export const get_project = async (postparam) => {

var response = await fetch(access_endpoint_link + "get_project", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  
}
export const get_career = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_career", format_request(postparam));
        var json = await response.json();
        //console.log(json);
        return return_response(response,json);
      

    }
export const get_project_by_category = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_project_by_category", format_request(postparam));
        var json = await response.json();
        //console.log(json);
        return return_response(response,json);
      
    }
export const get_award = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_award", format_request(postparam));
        var json = await response.json();
        //console.log(json);
        return return_response(response,json);
      
    }
    export const get_orgchart = async (postparam) => {

        var response = await fetch(access_endpoint_link + "get_orgchart", format_request(postparam));
            var json = await response.json();
            //console.log(json);
            return return_response(response,json);
          
        }
export const get_vision_mision = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_vision_mision", format_request(postparam));
        var json = await response.json();
        //console.log(json);
        return return_response(response,json);
      
    }
    export const get_policy = async (postparam) => {

        var response = await fetch(access_endpoint_link + "get_policy", format_request(postparam));
            var json = await response.json();
            //console.log(json);
            return return_response(response,json);
          
        }
export const get_company = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_company", format_request(postparam));
        var json = await response.json();
        //console.log(json);
        return return_response(response,json);
      
    }
export const get_testimonia = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_testimonia", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  
}




export const get_base_url =  () => {

    return host;
}