<template>
    <metainfo>

    <meta name="description" content="Home">
</metainfo>
   <pulse-loader v-if="isBusy" :loading="loading" :color="color" :size="size" class="loader_style"></pulse-loader>
   
   
   <div class="Home" v-else>
   
   

   <section class="hero-section" v-if="banner.length > 0">
      


      <div class="hero-slider owl-carousel" >

            <div class="hero-item set-bg" v-for="(data,index) in banner" :key="index" :style="{ backgroundImage: `url(${data.banner})` }"
            >
               <div class="container">
                  <div class="row">
                     <div class="col-xl-8">
                        
                        <h2 style="text-shadow: -4px -4px 0 #000, 4px -4px 0 #000, -4px 4px 0 #000, 4px 4px 0 #000;color:black"><span > {{data.title}}</span><span>{{data.description}}</span></h2>

                        <router-link to="/contact" class="site-btnn hvr-grow sb-white mr-4 mb-3">Contact Us</router-link>
                        <router-link to="/services" class="site-btnn hvr-grow sb-dark">our Services</router-link>
                     </div>
                  </div>

               </div>
               

         </div>
         
      </div>



   </section>
   <div v-if="Object.keys(about).length != 0" class="site-section" style="padding-bottom: 0px;">
      <div class="row justify-content-center no-gutters">
         <div class="col-md-12 heading-section text-center  mb-5" >
            <span class="subheading">About Us
            </span>
            <h2 class="mb-2">Our Company

            </h2>
         </div>
      </div>
      <div class="block-2 root-background director" >
         <div class="container">

            <div class="row">
               <div class="col-lg-6 mb-4 mb-lg-0">
                  <img :src="about.banner" alt="Image " class="img-fluid ">
               </div>
               <div class="col-lg-5 ml-auto">
                  <h3 class="section-subtitle">Director Expression</h3>
                  <h2 class="text-white mb-4">{{about.title}}</h2>
                  <p>{{about.description}}</p>
                  <div class="row my-5">
                     <div class="col-lg-12 d-flex align-items-center " v-for="(data,index) in about.list.split('+')" :key="index">
                        <div>
                           <p class="m-0  text-white">
                           * {{data}}

                           </p>

                        </div>
                     </div>
                   
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <section v-if="Object.keys(data_api).length != 0" class="milestones-section" style="background:url('industryinc/img/milestones-bg.jpg')">
      <div class="container text-white">
         <div class="row justify-content-center no-gutters">
            <div class="col-md-12 heading-section text-center  mb-5">
               <span class="subheading">Data</span>
            </div>
         </div>
         <div class="row">
            <div class="col-lg-3 col-sm-6" v-for="(data,index) in data_api" :key="index">
               <div class="milestone">
                  <div class="milestone-icon">
                     <i class="fa hvr-grow fa-4x"
                     :class="[data.banner]"
                     ></i>
                  </div>
                  <div class="milestone-text">
                     <span>{{data.title}}</span>
                     <h2  class="number" data-number="1000">{{data.number}}</h2>
                     <p>{{data.description}} </p>
                  </div>
                  
               </div>
            </div>
         </div>
      </div>
   </section>
   <section v-if="Object.keys(video).length != 0" class="video-section spad" style="
    background-attachment: fixed;
    background: linear-gradient(
      rgba(41, 18, 18, 0.45), 
      rgba(14, 6, 6, 0.45)
    );
    background-size:cover;
    background-repeat:no-repeat;
   "
   :style="{ backgroundImage: `url(${video.banner})` }"
   >
		<div class="container">
             <div class="col-md-12 heading-section text-center  mb-5">
               <span class="subheading">{{video.title}}</span>
               <h2 class="mb-2 text-white">{{video.description}}</h2>
            </div>


			<div class="row">
				<div class="col-lg-12">
					<div class="video-box" style="height:auto;">
						<a :href="video.video_link"  data-fancybox data-ratio="2" class="video-popup">
                  <!-- <span class="fa fa-play text-dark text-center" /> -->
							<i class="fa fa-play"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
	</section>
<!--  -->
   <section v-if="Object.keys(service).length != 0" class="features-section spad set-bg" data-setbg="/img/features-bg.jpg">
      <div class="container">
         <div class="row justify-content-center no-gutters">
            <div class="col-md-12 heading-section text-center  mb-5">
               <span class="subheading">Services</span>
               <h2 class="mb-2">Our Service</h2>
            </div>
         </div>


         <div class="row">
            <div class="col-lg-3 col-md-6" v-for="(data,index) in service" :key="index">
               <div class="feature-box" style="border:0.5px black solid">
                  <img :src="data.banner" class="hvr-grow" alt="" style="height:200px">
                  <div class="fb-text">
                     <h5>{{data.title}}</h5>
                                 <p>{{ ((data.description).length > 50) ? (data.description).substr(0, 50-1) + '&hellip;' : (data.description)}}</p>
                     <a  >
                     <router-link class="fb-more-btnn" :to="'/servicedetail/' + data.slug">View More</router-link>
                     </a>
                  </div>
               </div>
            </div>

           
         </div>

      </div>
   </section>
   <section v-if="Object.keys(project).length != 0" class="ftco-section ftco-portfolio">
      <div class="row justify-content-center no-gutters">
         <div class="col-md-12 heading-section text-center  mb-5">

            <span class="subheading">Projects</span>
            <h2 class="mb-2">Our Notable Projects</h2>
         </div>
      </div>
      <div class="container">
         <div class="row no-gutters">
            <div class="col-md-12 portfolio-wrap"  v-for="(data,index) in project" :key="index">
               <div class="row no-gutters align-items-center">
                     <router-link  :to="'/project/'" v-if="index % 2 == 0" class="col-md-5 bg-project hvr-grow hvr-grow" :style="{ backgroundImage: `url(${ data.banner})` }">
                     </router-link>
                     <router-link :to="'/project/'"  v-else class="col-md-5 order-md-last bg-project hvr-grow hvr-grow" :style="{ backgroundImage: `url(${data.banner})` }">
                     </router-link>
                  <div class="col-md-7">
                     <div class="text pt-5 pl-0 pl-lg-5 pl-md-4 ">
                        <div class="px-4 px-lg-4">
                           <div class="desc">
                              <div class="top " >
                                 <span class="subheading">{{data.date_of_project}}
                                 <img v-if="data.country == 'malaysia' " src="images/singapore-flag.jpg" class="hvr-push"  style="width:50px;height:50px" alt="">
                                 <img v-else src="images/flag-malaysia.png" class="hvr-push"  style="width:50px;height:50px" alt="">

                                 </span>
                                 <h2 class="mb-4"><a>{{data.title}}</a></h2>
                              </div>

                              <div class="absolute">
                                 <p>{{ ((data.description).length > 50) ? (data.description).substr(0, 50-1) + '&hellip;' : (data.description)}}</p>
                                 <p><a class="custom-btn"><router-link :to="'/projectdetail/' + data.slug">View More Project Detail</router-link></a></p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            
         </div>
      </div>
   </section>
   <section v-if="Object.keys(testimonia).length != 0" class="ftco-section testimony-section bg-light">
      <div class="container">
         <div class="row justify-content-center mb-5">
            <div class="col-md-7 text-center heading-section ">
               <span class="subheading">Clients</span>
               <h2 class="mb-3">Our Clients</h2>
            </div>
         </div>
         <div class="row ">
            <div class="col-md-12">
               <div class="carousel-testimony owl-carousel">
                  <div class="item" v-for="(data,index) in testimonia" :key="index">
                     <div class="testimony-wrap py-4  ">
                        <div class="text hvr-grow" >
                           <div class="d-flex align-items-center">
                              <div class="user-img" :style="{ backgroundImage: `url(${data.banner})` }"></div>
                              <div class="pl-3">
                                 <p class="name">{{data.title}}</p>
                                 <span class="position">{{data.description}}</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  
               </div>
            </div>
         </div>
      </div>
   </section>

   <section class="ftco-section">
      <div class="container">

         <div class="row justify-content-center no-gutters">
            <div class="col-md-12 heading-section text-center  mb-5">
               <span class="subheading" ref="quoteform" id="quoteform">Request</span>
               <h2 class="mb-2">Quote</h2>
            </div>
         </div>
         <div class="row">
            <div class="col-lg-12 order-md-last d-md-flex align-items-stretch">
               <div class="img w-100 img-2 img-overlay ml-md-2 p-4" style="background-image: url(images/about-2.jpg);">
                  <div class="request-quote py-5">
                     <div class="py-2">
                        <span class="subheading" >Be Part of our Business</span>
                        <h3>Request A Quote</h3>
                     </div>
                     <form @submit="submit_form" class="request-form ">
                        <div class="form-group">
                           <input type="text" v-model="form_firstname" class="form-control" placeholder="First Name">
                        </div>
                        <div class="form-group">
                           <input type="text" v-model="form_lastname" class="form-control" placeholder="Last Name">
                        </div>
                        <div class="form-group">
                           <div class="form-field">
                              <div class="select-wrap">
                                 <div class="icon-arr"><span class="ion-ios-arrow-down"></span></div>
                                 <select class="form-control" v-model="form_service">
                                    <option  value="Select service" disabled>Select Your Services</option>
                                    <option v-for="(data,index) in service" :key="index" :value="data.title">{{data.title}}</option>
                               
                                 </select>
                              </div>
                           </div>
                        </div>
                        <div class="form-group">
                           <input type="text" v-model="form_contact" class="form-control" placeholder="Phone">
                        </div>
                        <div class="form-group">
                           <textarea name="" id="" v-model="form_message" cols="30" rows="2" class="form-control" placeholder="Message"></textarea>
                        </div>
                        <div class="form-group">
                           <button type="submit" class="btn btn-primary py-3 px-4">Request A Quote</button></div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>

   </section>
   </div>
</template>
<style scoped>
 /* * {
    background: #000 !important;
    color: #0f0 !important;
    
    outline: solid #f00 1px !important;
    } */
.bg-project{
    height: 65vh;
    background-position: center;
    background-size: cover;
}
select > option{
   background:black;
}
.hero-item h2 span {
    padding-left: 25px;
    padding-bottom: 15px;
    display: inline-block;
    background: transparent;
}
  .loader_style{
    margin: auto;
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
  }
.hero-item h2 span:nth-child(1) {
    color: #00b04f;
}
.director{
    padding-bottom:0px;
}
 
 .video-section{
background: linear-gradient(
      rgba(41, 18, 18, 0.45), 
      rgba(14, 6, 6, 0.45)
    ),url({{video.banner}}); 
 }
.hero-item h2 {
    font-size: 90px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 34px;
    
    opacity: 0;
    top: 50px;
    position: relative;
}
@media (max-width: 767.98px) { 
.hero-item h2 {
   word-break: break-all;
    font-size: 50px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 34px;
    opacity: 0;
    top: 50px;
    position: relative;
}

     
}
/* .video-section:before{
  content: "";
  position: absolute;
  left: 0; right: 0;
  top: 0; bottom: 0;
  background:black;
} */
.fancybox-overlay{
z-index: 1000000;
}

.fancybox-wrap{
z-index: 1000001;
}
.milestones-section {
    padding-top: 35px;
    padding-bottom: 35px;
}
</style>

<script>
import {get_banner,add_quotation,get_video, get_project,get_base_url,get_about,get_data,get_service,get_testimonia} from '../API/api'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import {useMeta} from 'vue-meta'

// import carousel from 'vue-owl-carousel'
export default {
name: 'Home',
 setup () {
    useMeta({ title: 'Msjaya | Home ',
    description : 'descrip'
    })
  }, 
props: {
    msg: String
},
components: { PulseLoader },

data() {
    return {
      data: {},
      isBusy : true,
      project: {},
      base_url : "",
      video : {},

      about : {},
      service  : {},
form_service : "Select service",
      testimonia : {},
      data_api  : {},
      banner : []
    }
  },

  mounted(){
    
    
  },
  beforeMount(){
    this.get_data();

    this.getBase();

  },
  
  updated: function () {
     
  this.$nextTick(function () {
    // Code that will run only after the
    // entire view has been re-rendered
    
  
    window.$('.carousel-testimony').owlCarousel({
				center: true,
				loop: true,
				items:1,
				margin: 30,
				stagePadding: 0,
				nav: false,
				navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
				responsive:{
					0:{

						items: 1
					},
					600:{
						items: 2
					},
					1000:{
						items: 3
					}
				}
			});
       

  })
},
  methods: {
 
   submit_form(){
       let name = this.form_firstname + this.form_lastname
         add_quotation({name : name,service: this.form_service , contact : this.form_contact,message : this.form_message}).then(data => {
         //  alert(JSON.stringify(banner))
         console.log(data)
			alert("Form Submitted , Thank you " + name);         
      });
   },
    async getBase(){
      let host = get_base_url();
      this.base_url = host;
   },
    async get_data(){
       get_banner({post : 1}).then(banner => {
         //  alert(JSON.stringify(banner))
          this.banner = banner;
         setTimeout(()=>{
  window.$('.hero-slider').owlCarousel({
			nav: true,
         // autoplayTimeout: 6000,
			dots: true,
			loop: true,
			navText: ['<i class="fa hvr-grow fa-angle-left"></i>','<i class="fa hvr-grow fa-angle-right"></i>'],
			autoplay: true,
			items: 1,
			animateOut: 'fadeOut',
			animateIn: 'fadeIn',
		});
      
         },1000)
      });
       get_testimonia({post : 1}).then(testimonia => {
         //  alert(JSON.stringify(banner))
          this.testimonia = testimonia;
      });
      
       get_project({post : 1}).then(project => {
         //  alert(JSON.stringify(project.filter(arr=>arr.is_home != 1)))
          this.project = project.filter(arr=>arr.is_home == 1);
                   this.isBusy = false;

         
      });
      get_data({post : 1}).then(data => {
         //  alert(JSON.stringify(banner))
          this.data_api = data;
                   this.isBusy = false;

         
      });
        get_video({post : 1}).then(data => {
         //  alert(JSON.stringify(banner))
          this.video = data[0];
                   this.isBusy = false;

         
      });
      get_service({post : 1}).then(service => {
         //  alert(JSON.stringify(banner))
          this.service = service;
                             this.isBusy = false;

         
      });
       get_about({post : 1}).then(about => {
         //  alert(JSON.stringify(banner))

          this.about = about.find(arr=>arr.type == "abouthomeone");
                             this.isBusy = false;

      });
    
    }
  }
}
</script>