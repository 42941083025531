<template>
  <pulse-loader v-if="isBusy" :loading="loading" :color="color" :size="size" class="loader_style"></pulse-loader>

   <div class="Award" v-else>
<section class="hero-wrap hero-wrap-2" style=" background-image: url('engineerscss/images/hero1.jpg');" data-stellar-background-ratio="0.5">
   <div class="overlay"></div>
   <div class="container">
      <div class="row no-gutters slider-text js-fullheight align-items-end">
         <div class="col-md-9  pb-5">
            <p class="breadcrumbs"><span class="mr-2"><a href="index.html">Home <i class="fa hvr-grow fa-chevron-right"></i></a></span> <span>About us <i class="fa hvr-grow fa-chevron-right"></i></span></p>
            <h1 class="mb-3 bread">Award</h1>
            <!-- <a data-fancybox="gallery" href="images/flag-malaysia.png"><img src="images/flag-malaysia.png" style="width:50px"></a> -->

         </div>
      </div>
   </div>
</section>
<section class="ftco-section container">
    
<div class="row justify-content-center no-gutters">
      <div class="col-md-12 heading-section text-center  mb-5">
         <span class="subheading">About Us</span>
         <h2 class="mb-2">Our Award</h2>
      </div>
   </div>
    <!-- v-for="(data,index) in award" :key="index" -->

<div class="gallery" id="gallery">
    <div class="gallery-item" v-for="(data,index) in award" :key="index">
        <div class="content" ><img :src=" data.banner" alt=""></div>
    </div>
    
   
   
</div>
 
</section>
    </div>
</template>
<style scoped>

body {
  background-color: #eee;
}
.hello {
  opacity: 1 !important;
}
.full {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.full .content {
  background-color: rgba(0,0,0,0.75) !important;
  height: 100%;
  width: 100%;
  display: grid;
}
.full .content img {
  left: 50%;
  transform: translate3d(0, 0, 0);
  animation: zoomin 1s ease;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.byebye {
  opacity: 0;
}
.byebye:hover {
  transform: scale(0.2) !important;
}
.gallery {
  display: grid;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: 8px;
}
.gallery img {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 0 16px #333;
  transition: all 1.5s ease;
}
.gallery img:hover {
  box-shadow: 0 0 32px #333;
}
.gallery .content {
  padding: 4px;
}
.gallery .gallery-item {
  transition: grid-row-start 300ms linear;
  transition: transform 300ms ease;
  transition: all 0.5s ease;
  cursor: pointer;
}
.gallery .gallery-item:hover {
  transform: scale(1.025);
}
@media (max-width: 600px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  }
}
@media (max-width: 400px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}
@-moz-keyframes zoomin {
  0% {
    max-width: 50%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 50%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 100%;
    transform: rotate(0deg);
  }
}
@-webkit-keyframes zoomin {
  0% {
    max-width: 50%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 50%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 100%;
    transform: rotate(0deg);
  }
}
@-o-keyframes zoomin {
  0% {
    max-width: 50%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 50%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 100%;
    transform: rotate(0deg);
  }
}
@keyframes zoomin {
  0% {
    max-width: 50%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 50%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 100%;
    transform: rotate(0deg);
  }
}
</style>

<script>
import {get_award,get_base_url} from '../API/api'
   import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import {useMeta} from 'vue-meta'

export default {
name: 'Award',
props: {
    msg: String
},
 setup () {
    useMeta({ title: 'Msjaya | Award ',
    })
  }, 
components: { PulseLoader },

updated: function () {
    // Code that will run only after the
    // entire view has been re-rendered
     var gallery = document.querySelector('#gallery');
    var getVal = function (elem, style) { return parseInt(window.getComputedStyle(elem).getPropertyValue(style)); };
    var getHeight = function (item) { return item.querySelector('.content').getBoundingClientRect().height; };
    var resizeAll = function () {
        var altura = getVal(gallery, 'grid-auto-rows');
        var gap = getVal(gallery, 'grid-row-gap');
        gallery.querySelectorAll('.gallery-item').forEach(function (item) {
            var el = item;
            el.style.gridRowEnd = "span " + Math.ceil((getHeight(item) + gap) / (altura + gap));
        });
    };
    gallery.querySelectorAll('img').forEach(function (item) {
        item.classList.add('byebye');
        if (item.complete) {
            console.log(item.src);
        }
        else {
            item.addEventListener('load', function () {
                var altura = getVal(gallery, 'grid-auto-rows');
                var gap = getVal(gallery, 'grid-row-gap');
                var gitem = item.parentElement.parentElement;
                gitem.style.gridRowEnd = "span " + Math.ceil((getHeight(gitem) + gap) / (altura + gap));
                item.classList.remove('byebye');
            });
        }
    });
    window.addEventListener('resize', resizeAll);
    gallery.querySelectorAll('.gallery-item').forEach(function (item) {
        item.addEventListener('click', function () {        
            item.classList.toggle('full');        
        });
    });

},

data() {
    return {
      data: {},
      award : {},
                  isBusy : true,

      base_url : "",
    }
  },
  beforeMount(){
    this.get_data();
    this.getBase();
  },

  methods: {
    
    async getBase(){
      let host = get_base_url();
      this.base_url = host;
   },
    async get_data(){
       get_award({post : 1}).then(award => {
          this.award = award;
                                       this.isBusy = false;

      });
    }
  }
}
</script>
