<template>
  <pulse-loader v-if="isBusy" :loading="loading" :color="color" :size="size" class="loader_style"></pulse-loader>

   <div class="Project" v-else>

    <!-- END nav -->
  <section class="hero-wrap hero-wrap-2"  style="background-image: url('images/bg_2.jpg');" data-stellar-background-ratio="0.5">
   <div class="overlay"></div>
   <div class="container">
      <div class="row no-gutters slider-text js-fullheight align-items-end">
         <div class="col-md-9  pb-5">
            <p class="breadcrumbs"><span class="mr-2"><a href="/">Home <i class="fa hvr-grow fa-chevron-right"></i></a></span> <span>Projects <i class="fa hvr-grow fa-chevron-right"></i></span></p>
            <h1 class="mb-3 bread">Projects</h1>
         </div>
      </div>
   </div>
</section>

<div class="row mt-5 container" v-if="Object.keys(project_category).length != 0" style="margin:auto">
   <div class="col-md-12">
      <div class="tab" role="tabpanel">
         <!-- Nav tabs -->
         <ul class="nav nav-tabs" role="tablist">
            <li role="presentation" v-for="(data,index) in project_category" :key="index" class="active">
               <a style="padding:0px;margin-left:20px;background:black"  v-if="data.category == current_category"   @click="change_project_category(data.category)" :href="'#Section' + index" aria-controls="home" role="tab" data-toggle="tab">{{(data.category)}}</a>
               <a style="padding:0px;margin-left:20px;"  v-else   @click="change_project_category(data.category)" :href="'#Section' + index" aria-controls="home" role="tab" data-toggle="tab">{{(data.category)}}</a>

            </li>
         </ul>
         <!-- Tab panes -->
      </div>
   </div>
</div>

<div class="tab-content tabs" >
<div role="tabpanel" class="tab-pane fade show active" >
   <section class="ftco-section ftco-portfolio">
      <div class="container">
         <div class="row justify-content-center no-gutters" >
            <div class="col-md-12 heading-section text-center  mb-5" v-if="Object.keys(project).length != 0">
               <span class="subheading">Project</span>
               <h2 class="mb-2">Our Project</h2>
            </div>
         </div>
         <div class="row no-gutters">
            <div class="col-md-12 portfolio-wrap"  v-for="(data,index) in project" :key="index">
               <div class="row no-gutters align-items-center">
                     <div  v-if="index % 2 == 0" class="col-md-5 bg-project hvr-grow hvr-grow" :style="{ backgroundImage: `url(${data.banner})` }">
                     </div>
                     <div  v-else class="col-md-5 order-md-last bg-project hvr-grow hvr-grow" :style="{ backgroundImage: `url(${data.banner})` }">
                     </div>
                  <div class="col-md-7">
                     <div class="text pt-5 pl-0 pl-lg-5 pl-md-4 ">
                        <div class="px-4 px-lg-4">
                           <div class="desc">
                              <div class="top " >
                                 <span class="subheading">{{data.date_of_project}}
                                    
                                 <img v-if="data.country == 'malaysia' " src="images/singapore-flag.jpg" class="hvr-push"  style="width:50px;height:50px" alt="">
                                 <img v-else src="images/flag-malaysia.png" class="hvr-push"  style="width:50px;height:50px" alt="">


                                 </span>
                                 <h2 class="mb-4"><a>{{data.title}}</a></h2>
                              </div>
                              <div class="absolute">
                                 <p>{{ ((data.description).length > 50) ? (data.description).substr(0, 50-1) + '&hellip;' : (data.description)}}</p>
                                 <p><a><router-link  class="custom-btn" :to="'/projectdetail/' + data.slug">View More</router-link></a></p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            
         </div>
      </div>
      <div class="container-fluid">
         <div class="row justify-content-center no-gutters" v-if="Object.keys(projectgrid).length != 0">
            <div class="col-md-12 heading-section text-center  mb-5">
               <span class="subheading">Project</span>
               <h2 class="mb-2">More Project</h2>
            </div>
         </div>
         <div class="row no-gutters">
            <div class="col-md-6 col-lg-3  fadeInUp d" v-for="(data,index) in projectgrid" :key="index">
               <div class="project">
                  <img :src="data.banner" class="img-fluid"  >
                  <div class="text">
                     <span>{{data.category}} ({{data.country}})</span>
                     <h3><a><router-link :to="'/projectdetail/' + data.slug">{{data.title}}</router-link></a></h3>
                  </div>
                  <a href="images/work-1.jpg" class="icon image-popup d-flex justify-content-center align-items-center">
                  <span class="fa hvr-grow fa-expand"></span>
                  </a>
               </div>
            </div>
           
         </div>
      </div>
   </section>
   <section class="about-section spad">
      <div class="container">
         <div class="row justify-content-center no-gutters">
            <div class="col-md-12 heading-section text-center  mb-5">
               <span class="subheading">Project</span>
               <h2 class="mb-2">More Project</h2>
            </div>
         </div>
         <div class="row">
            <div class="col-lg-6">
               <img src="industryinc/img/about.jpg" alt="">
            </div>
            <div class="col-lg-6">
               <div class="about-text">
                  <div class="col-12 block-7">
                     <ul class="pricing-text mb-2">
                        <li v-for="(data,index) in projectlist" :key="index"><span class="fa hvr-grow fa-check-circle mr-2">
                           </span>{{data.title}}[{{data.client}}] ({{data.date_of_project}},{{data.country}})</li>
                     </ul>
                  </div>
                  <div class="about-sign">
                     <div class="sign">
                        <img src="industryinc/img/sign.png" alt="">
                     </div>
                     <div class="sign-info">
                        <!-- <h5>Michael Smith</h5> -->
                        <!-- <span>CEO</span> -->
                     </div>
                  </div>
               </div>
            </div>
         </div>

   </div>
</section>
	</div>
</div>

    </div>
</template>
<style scoped>
.bg-project{
    height: 65vh;
    background-position: center;
    background-size: cover;
}
   .tab{ font-family: 'Nunito', sans-serif; }
.tab .nav-tabs{
    background-color: transparent;
    border: none;
}
.tab .nav-tabs li a{
    color: #222;
    background: transparent;
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 15px 10px;
    margin: 0;
    border: none;
    border-radius: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease 0s;
}
.tab .nav-tabs li:last-child a{ margin-right: 0; }
.tab .nav-tabs li a:hover,
.tab .nav-tabs li.active a{
    color: #222;
    background: #fff;
    border: none;
}
.tab .nav-tabs li.active a{ color: #6CBF1C; }
.tab .nav-tabs li a:before,
.tab .nav-tabs li a:after{
    content: "";
    height: 7px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.5s ease 0s;
}
.tab .nav-tabs li a:after{
    background-color: #6CBF1C;
    height: 100%;
    opacity: 0;
}
.tab .nav-tabs li.active a:before,
.tab .nav-tabs li a:hover:before{
    height: 100%;
    opacity: 0;
}
.tab .nav-tabs li.active a:after,
.tab .nav-tabs li a:hover:after{
    height: 7px;
    opacity: 1;
}
.tab .tab-content{
    color: #555;
    background: #fff;
    font-size: 15px;
    letter-spacing: 1px;
    line-height: 23px;
    padding: 20px;
}
.tab .tab-content h3{
    color: #222;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0 0 7px 0;
}
@media only screen and (max-width: 479px){
    .tab .nav-tabs li{ width: 100%; }
    .tab .nav-tabs li a{ margin: 0 0 10px; }
    .tab .tab-content h3{ font-size: 18px; }
}
</style>
<script>
// import {test} from '../API/api'
import {get_base_url, get_project, get_project_by_category} from '../API/api'
   import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import {useMeta} from 'vue-meta'

export default {
name: 'Project',
props: {
   msg: String
},
   setup () {
      useMeta({ title: 'Msjaya | Project ',
      })
    }, 
components: { PulseLoader },
data() {
    return {
      data: {},
       base_url : "",
   project : {},
      projectcategory : {},
            isBusy : true,

   current_category : "",
   projectgrid : {},
   projectlist : {},
    }
  },
   beforeMount(){
    this.get_data();
    this.getBase();

  },
   methods: {
   change_project_category(category) {
      // alert(category);
      this.current_category = category;
      window.localStorage.setItem("category",category);
      // `this` inside methods points to the current active instance
         get_project_by_category({category : category}).then(project => {
          this.project = project.filter(arr=>arr.is_lastest == "1");
         this.projectgrid = project.filter(arr=>arr.is_home== "1");
          this.projectlist = project.filter(arr=>arr.is_list == "1");
                             this.isBusy = false;


      });
      },
 DistinctRecords(MYJSON,prop) {
  return MYJSON.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
 });
},
    async getBase(){
      let host = get_base_url();
      this.base_url = host;
   },
    async get_data(){
       
      
        get_project({post : 1}).then(project => {

           this.project_category = this.DistinctRecords(project,"category");
          this.project = project.filter(arr=>arr.is_lastest == "1");
         this.projectgrid = project.filter(arr=>arr.is_home== "1");
         this.projectlist = project.filter(arr=>arr.is_list == "1");
          console.log(JSON.stringify(this.projectlist));
                             this.isBusy = false;
         let activecategory = window.localStorage.getItem("category");
         if(Object.prototype.hasOwnProperty.call(window.localStorage, "category")){
            this.change_project_category(activecategory);
         }else{
            this.change_project_category("Insulation");
         }

      });

    
    
  }

   }
//   beforeMount(){
//     this.getName();
//   },
//   methods: {
//     async getName(){
//        test({post : 1}).then(test => {
//           this.data.test = test;
//       });
//     }
//   }
}
</script>
