import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'    
import { createMetaManager } from 'vue-meta'

// import { createMetaManager, defaultConfig, resolveOption, useMeta } from 'vue-meta'
// import VueMeta from 'vue-meta'
// const decisionMaker5000000 = resolveOption((prevValue, context) => {
//   const { uid = 0 } = context.vm || {}
//   if (!prevValue || prevValue < uid) {
//     return uid
//   }
// })

// const metaManager = createMetaManager({
//   ...defaultConfig,
//   esi: {
//     group: true,
//     namespaced: true,
//     attributes: ['src', 'test', 'text']
//   }
// }, decisionMaker5000000)

// useMeta(
//   {
//     og: {
//       something: 'test'
//     }
//   },
//   metaManager
// )

// createApp(App).use(VueMeta).use(router)
// // Vue.use(VueMeta)
// // createApp(App).use(VueMeta).use(router).mount('#app')
// // var app = createApp(App).use(router).use(store).use(createMetaManager()); // add this line
// // router.isReady()
// // app.mount('#app')
createApp(App)
  .use(router)
//   .use(store)
  .use(createMetaManager()) 
  .mount('#app')
  // add this line

// await router.isReady()
// app.mount('#app')