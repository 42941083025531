<template>
  <pulse-loader v-if="isBusy" :loading="loading" :color="color" :size="size" class="loader_style"></pulse-loader>
   
   
   <div class="Services" v-else>

    <!-- END nav -->
    
   <section class="hero-wrap hero-wrap-2" style=" background-image: url('engineerscss/images/hero1.jpg');" data-stellar-background-ratio="0.5">
   <div class="overlay"></div>
   <div class="container">
      <div class="row no-gutters slider-text js-fullheight align-items-end">
         <div class="col-md-9  pb-5">
            <p class="breadcrumbs"><span class="mr-2"><a href="index.html">Home <i class="fa hvr-grow fa-chevron-right"></i></a></span> <span>Services <i class="fa hvr-grow fa-chevron-right"></i></span></p>
            <h1 class="mb-3 bread">Services</h1>
         </div>
      </div>
   </div>
</section>
<!-- Services section end  -->
<!-- Reserch section  -->
<section class="reserch-section spad">
   <div class="container">
   <div class="row">
   <div class="col-lg-4">
      <ul class="nav nav-tabs reserch-tab-menu" role="tablist">
         <li class="nav-item" v-for="(data,index) in services" :key="index">
            <a class="nav-link"  :class="{ show: index == 0 , active  : index == 0}" data-toggle="tab" :href="'#tab-' + index" role="tab" :aria-controls="'tab-' + index" >{{data.title}}</a>
         </li>
         
      </ul>
   </div>

   <div class="col-lg-8">
   <div class="tab-content reserch-tab">
      <!-- single tab content -->
      <div class="tab-pane" :class="{ show: index == 0 , active  : index == 0}"  v-for="(data,index) in services" :key="index" :id="'tab-' + index" role="tabpanel" :aria-labelledby="'tab-' + index">
         <!-- <h2>We produce or supply {{data.title}}</h2> -->
               <router-link :to="'/servicedetail/' + data.slug">
             <div class="col-md-12 d-flex align-items-stretch  block-7">
               <div class="media block-6 services d-block text-center px-3 pb-4 hvr-grow hvr-grow" style="margin:auto">
                  <div class="icon d-flex justify-content-center align-items-center"><span class="flaticon-lamp"></span></div>
                    <div class="media-body py-md-4 ">
                        <h2>{{data.title}}</h2>
                        <p>{{ ((data.description).length > 100) ? (data.description).substr(0, 100-1) + '&hellip;' : (data.description)}}</p>
                            <a class="btn round-pill hvr-pulse text-white" style="margin:5px;background-color:#016e32;">
                            <router-link :to="'/servicedetail/' + data.slug">View more</router-link>
                            </a>
                    </div>

         </div>      
         </div>
         
               </router-link>
              </div>
            </div>

         </div>
         </div>


   </div>
</section>

    </div>
</template>
<style scoped>

      .block-7{
        background-color: #0e2b43 !important;
      }
      .reserch-tab h2 {
    text-align: center;
}
</style>
<script>
// import {test} from '../API/api'
import {get_base_url,get_service} from '../API/api'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import {useMeta} from 'vue-meta'

export default {
name: 'Services',
props: {
    msg: String
},
 setup () {
    useMeta({ title: 'Msjaya | Services ',
    })
  }, 
components: { PulseLoader },

data() {
    return {
      data: {},
      servicetitle : {},
      services : {},
            isBusy : true,

    }
  },
  beforeMount(){
    this.get_data();
    this.getBase();

  },
   methods: {

    async getBase(){

      let host = get_base_url();
      this.base_url = host;
   },
    async get_data(){
       
       get_service({post : 1}).then(service => {


          this.services = service;
                             this.isBusy = false;

      });

    
    }
  }
}
</script>
