<template>
   <div class="Contact">
<section class="hero-wrap hero-wrap-2" style=" background-image: url('engineerscss/images/hero1.jpg');" data-stellar-background-ratio="0.5">
   <div class="overlay"></div>
   <div class="container">
      <div class="row no-gutters slider-text js-fullheight align-items-end">
         <div class="col-md-9  pb-5">
            <p class="breadcrumbs"><span class="mr-2"><a href="index.html">Home <i class="fa hvr-grow fa-chevron-right"></i></a></span> <span>Contact us <i class="fa hvr-grow fa-chevron-right"></i></span></p>
            <h1 class="mb-3 bread">Contact</h1>

         </div>
      </div>
   </div>
</section>
	<section class="contact-section spad">

	<div class="map-section spad" style="margin-top:20px">
		<div class="container">
			<div class="map-info" style="visibility:hidden">
				<img :src="company.banner" style="width:120	px;">
				<p>{{company.title}}</p>
			</div>
		</div>
		<div class="map">
			<div class="mapouter"><div class="gmap_canvas"><iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=lot%2015536%20jalan%20besar%20%20lumut%20port%20industrial%20park%20kg%20acheh%2032000%20sitiawan&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="yes" marginheight="0" marginwidth="0"></iframe></div></div>
			<!-- <iframe  src="https://maps.google.com/maps?q=lot%2015536%20jalan%20besar%20%20lumut%20port%20industrial%20park%20kg%20acheh%2032000%20sitiawan&t=&z=13&ie=UTF8&iwloc=&output=embed" style="border:0" allowfullscreen></iframe> -->
			<!-- <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14376.077865872314!2d-73.879277264103!3d40.757667781624285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1546528920522" style="border:0" allowfullscreen></iframe> -->
		</div>
	</div>
	</section>
	<section class="contact-section spad">
		<div class="container">
			<div class="row">
				<div class="col-lg-4">
					<div class="contact-text">
						<h2>Get in Touch</h2>
						<p>Welcome to contact </p>
						<div class="headerr-info-box">
							<div class="hib-icon">
                            <i class="fa hvr-grow fa-phone fa-2x  hvr-grow  root-color"></i>
							</div>
							<div class="hib-text">
								<h6>{{company.contact}}</h6>
								<p>{{company.email}}</p>
							</div>
						</div>
						<div class="headerr-info-box">
							<div class="hib-icon">
                            <i class="fa hvr-grow fa-map-marker fa-2x  hvr-grow  root-color" aria-hidden="true"></i>

							</div>
							<div class="hib-text">
								<p>{{company.address}}</p>
							</div>
						</div>
					</div>
				</div>
				
				<div class="col-lg-8">
					<form class="contact-form"   @submit="submit_form">
						<div class="row">
							<div class="col-lg-6">
								<input type="text" v-model="name" placeholder="Your Name">
							</div>
							<div class="col-lg-6">
								<input type="text" v-model="email" placeholder="Your Email">
							</div>
							<div class="col-lg-4">
							</div>
							<div class="col-lg-12">
								<input type="text" v-model="subject" placeholder="Subject">
								<textarea class="text-msg" v-model="message" placeholder="Message"></textarea>
								<button class="site-btnn hvr-grow" type="submit">send message</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</section>
    </div>

</template>

<script>
// import {test} from '../API/api'
import {get_company,add_contact} from '../API/api'
import {useMeta} from 'vue-meta'

export default {
name: 'Contact',
 setup () {
    useMeta({ title: 'Msjaya | Contact ',
    })
  }, 
props: {
    msg: String
},
data() {
    return {
      company: {}
    }
  },
    beforeMount(){
    this.get_data();


  },
  methods: {
   submit_form(){
		alert("Form Submitted");         
		add_contact({name : this.name,email: this.email , subject : this.subject,message : this.message}).then(data => {
			console.log(data)

		});
   },
  
    async get_data(){
      
       get_company({post : 1}).then(company => {
         //  alert(JSON.stringify(banner))

          this.company = company.find(arr=>arr.is_primary == "1");
      });
    
    }
  }
}
</script>
