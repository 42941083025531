<template>
<metainfo>
    <template v-slot:title="{ content }" >{{ content ? `${content} ` : `Msjaya` }}</template>
  </metainfo>
  <div id="app">
      <!-- navbar -->
     
           <Header />
  <suspense>

         <router-view/>
  </suspense>

           <Footer />
        
    
  </div>
</template>

<script >
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import {useMeta} from 'vue-meta'
export default {
  name: 'App',
  setup () {
    useMeta({
      title: '',
      htmlAttrs: { lang: 'en', amp: true }
    })
  },
  components: {
    Header,
    Footer,
    


  },
  created(){
  
  }
}

</script>

<style>
/* @import './assets/css/style3.css'; */

.moveInUp-enter-active{
  animation: fadeIn 2s ease-in;
}
@keyframes fadeIn{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    opacity: 1;
  }
}

.moveInUp-leave-active{
  animation: moveInUp .3s ease-in;
}
@keyframes moveInUp{
 0%{
  transform: translateY(0);
 }
  100%{
  transform: translateY(-400px);
 }
}
</style>
