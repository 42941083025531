<template>
  <pulse-loader v-if="isBusy" :loading="loading" :color="color" :size="size" class="loader_style"></pulse-loader>

   <div class="Investment" v-else>
<section class="hero-wrap hero-wrap-2" style="background-image: url('engineerscss/images/hero1.jpg');" data-stellar-background-ratio="0.5">
   <div class="overlay"></div>
   <div class="container">
      <div class="row no-gutters slider-text js-fullheight align-items-end">
         <div class="col-md-9  pb-5">
            <p class="breadcrumbs"><span class="mr-2"><a href="index.html">Home <i class="fa hvr-grow fa-chevron-right"></i></a></span> <span>Investment <i class="fa hvr-grow fa-chevron-right"></i></span></p>
            <h1 class="mb-3 bread" style="word-break: break-all;">Investment</h1>
         </div>
      </div>
   </div>
</section>
<section class="ftco-section"  v-if="Object.keys(investment).length != 0" >
   <div class="container">
      <div class="row justify-content-center mb-5">
         <div class="col-md-7 heading-section text-center ">
            <span class="subheading">Investment</span>
            <h2>Investment </h2>
         </div>
      </div>
      <div class="row">
         <div class="col-md-4 " v-for="(data,index) in investment" :key="index" >
            <div class="block-7">
               <img :src="data.banner" class="img hvr-grow" alt="" />
               <div class="p-4">
                  <ul class="pricing-text mb-2">
                     <li><span class="fa hvr-grow fa-check-circle mr-2"></span>{{data.description}}</li>
                  </ul>
               </div>
               <div class="d-lg-flex align-items-center w-100 bg-light py-2 px-4">
                  <span class="price"> {{data.price}}</span>
                  <p class="w-100 mb-0">
                     <a  class="btn btn-primary d-block px-2 py-3 hvr-grow"  data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Contact Us</a>
                  </p>
               </div>
            </div>
         </div>
         
      </div>
   </div>
</section>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Investment Message</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <form @submit="submit_form">
               <div class="form-group">
                  <label for="recipient-name" class="col-form-label">Name:</label>
                  <input type="text" v-model="name" class="form-control" id="recipient-name">
               </div>
               <div class="form-group">
                  <label for="recipient-name" class="col-form-label">Company Name:</label>
                  <input type="text" v-model="company_name" class="form-control" id="recipient-name">
               </div>
               <div class="form-group">
                  <label for="recipient-name" class="col-form-label">Location:</label>
                  <input type="text" v-model="location" class="form-control" id="recipient-name">
               </div>
               <div class="form-group">
                  <label for="recipient-name" class="col-form-label">Phone number:</label>
                  <input type="tel" v-model="contact" class="form-control" id="recipient-name">
               </div>
               <div class="form-group">
                  <label for="message-text" class="col-form-label">Message:</label>
                  <textarea class="form-control" v-model="message" id="message-text"></textarea>
               </div>
         <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-primary">Send message</button>
         </div>
            </form>
      </div>
   </div>
</div>
    </div>
    </div>

</template>
<script>
import {get_base_url, get_investment,add_investment} from '../API/api'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import {useMeta} from 'vue-meta'

export default {
name: 'Project',
props: {
    msg: String
},
 setup () {
    useMeta({ title: 'Msjaya | Investment ',
    })
  }, 
components: { PulseLoader },

data() {
    return {
      data: {},
       base_url : "",
            isBusy : true,

   investment : {},
    }
  },
   beforeMount(){
    this.get_data();
    this.getBase();

  },
   methods: {
submit_form(){
			alert("Form Submitted , Thank you " + this.company_name);         
			add_investment({name : this.name,company_name: this.company_name , location : this.location, contact : this.contact,message : this.message}).then(data => {
				console.log(data)

			});
	},
    async getBase(){
      let host = get_base_url();
      this.base_url = host;
   },
    async get_data(){
       
      
        get_investment({post : 1}).then(investment => {
           this.investment = investment;
                                        this.isBusy = false;

        

      });
    
    
    }
  }
//   beforeMount(){
//     this.getName();
//   },
//   methods: {
//     async getName(){
//        test({post : 1}).then(test => {
//           this.data.test = test;
//       });
//     }
//   }
}
</script>
