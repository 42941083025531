import { createWebHistory , createRouter } from "vue-router"
import Home from '@/components/Home'
import About from '@/components/About'
import Services from '@/components/Services'
import Career from '@/components/Career'
import Contact from '@/components/Contact'
import Investment from '@/components/Investment'
import News from '@/components/News'
import Award from '@/components/Award'
import Project from '@/components/Project'
import Supply from '@/components/Supply'
import Orgchart from '@/components/Orgchart'
import NewsDetail from '@/components/NewsDetail'
import ServiceDetail from '@/components/ServiceDetail'
import ProjectDetail from '@/components/ProjectDetail'

// const BASE_URL = "/vue/";
const history = createWebHistory();
const router = createRouter({
    history,

    
    routes: [
        {
            path :'/',
            component : Home,
            meta: {
                title: 'Home Page - Example App',
                metaTags: [
                  {
                    name: 'description',
                    content: 'The home page of our example app.'
                  },
                  {
                    property: 'og:description',
                    content: 'The home page of our example app.'
                  }
                ]
              },
        },
        {
            path :'/about',
            component : About,
        },
        {
            path :'/award',
            component : Award,
        }
        ,
        {
            path :'/project',
            component : Project,
        }
        ,
        {
            path :'/services',
            component : Services,
        }
        ,
        {
            path :'/orgchart',
            component : Orgchart,
        }
        ,
        {
            path :'/investment',
            component : Investment,
        }
        ,
        {
            path :'/news',
            component : News,
        }
        ,
        {
            path :'/supply',
            component : Supply,
        }
        ,
        {
            path :'/contact',
            component : Contact,
        }
        ,
        {
            path :'/projectdetail/:id',
            component : ProjectDetail,
        }
        ,
        {
            path :'/newsdetail/:id',
            component : NewsDetail,
        }
        ,
        {
            path :'/servicedetail/:id',
            component : ServiceDetail,
        }
        ,
        {
            path :'/career',
            component : Career,
        },

    ],
    scrollBehavior() {
        window.scrollTo(0,0);
    }
    // scrollBehavior: function (to) {
    //     if (to.hash) {
    //       return {
    //         selector: to.hash
    //       }
    //     }
    //   },
});

export default router;